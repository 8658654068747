<template>
    <section>
        <div class="row mx-0 my-3">
            <div class="col-8 bg-white br-8 py-3 px-3">
                <p class="col-12 px-0 mb-3 f-18 f-600 text-general">
                    Seleccione el tipo de cupón
                </p>
                <div class="row mx-0 mt-3">
                    <div class="col-4 d-middle">
                        <div :class="datosBasicos.tipo == 1 ? 'active-button' : 'inactive-button disabled-div'" class="d-middle cr-pointer br-8 px-4 py-2" style="height:32px;">
                            <i v-if="datosBasicos.tipo == 1" class="icon-ok-circled f-18 text-white" />
                            <div v-else class="br-50 border-general2 mx-1" style="width:18px;height:18px;" />
                            Cupón
                        </div>
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px;">
                                Este cupón se aplicará siempre y cuando el {{ $config.cliente }} cumpla con las condiciones del mismo.
                            </div>
                            <i class="icon-info-circled-alt text-general2 f-18" />
                        </el-tooltip>
                    </div>
                    <div class="col-4 d-middle">
                        <div :class="datosBasicos.tipo == 2 ? 'active-button' : 'inactive-button disabled-div'" class="d-middle cr-pointer br-8 px-4 py-2" style="height:32px;">
                            <i v-if="datosBasicos.tipo == 2" class="icon-ok-circled f-18 text-white" />
                            <div v-else class="br-50 border-general2 mx-1" style="width:18px;height:18px;" />
                            Bono fúturo
                        </div>
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" style="max-width:200px;">
                                El {{ $config.cliente }} podrá tener el beneficio de un bono siempre y cuando realice una compra inicial que cumpla con ciertas condiciones
                            </div>
                            <i class="icon-info-circled-alt text-general2 f-18" />
                        </el-tooltip>
                    </div>
                </div>
                <div class="row mx-0 mt-4 disabled-div">
                    <div class="col-4 d-middle">
                        <div class="d-middle cr-pointer br-10 py-2">
                            <input v-model="datosBasicos.aplica_usuarios" :value="1" type="radio" class="option-input radio" />
                            Aplica a todos los {{ datosBasicos.destinatario == 1 ? $config.cliente : $config.vendedor }}
                        </div>
                    </div>
                    <div class="col-auto d-middle">
                        <div class="d-middle cr-pointer br-10  py-2">
                            <input v-model="datosBasicos.aplica_usuarios" :value="2" type="radio" class="option-input radio" />
                            Aplica a {{ datosBasicos.destinatario == 1 ? $config.cliente : $config.vendedor }}  especificos
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="datosBasicos.tipo == 2" class="col px-0">
            <div class="row mx-0 pt-4">
                <div
                class="col-4 pt-0 d-middle-center cr-pointer"
                :class="tab=='cupon1'? 'border-b-red text-general-red':'border-bottom'"
                @click="tab='cupon1'"
                >
                    Primer Cupón
                </div>
                <div
                class="col-4 pt-0 d-middle-center cr-pointer"
                :class="tab=='cupon2'? 'border-b-red text-general-red':'border-bottom'"
                @click="tab='cupon2'"
                >
                    Cupón Futuro
                </div>
            </div>
            <div class="mx-0">
                <primer-cupon v-show="tab === 'cupon1'" />
                <segundo-cupon v-if="tab === 'cupon2'" />
            </div>
        </div>

        <template v-if="datosBasicos.tipo == 1">
            <primer-cupon :crear="false" :tipo="cupon" />
        </template>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    components: {
        primerCupon: () => import('../componentes/primerCupon'),
        segundoCupon: () => import('../componentes/segundoCupon'),
    },
    data(){
        return {
            tab:'cupon1',
            cupon: 1,


            value: '',
            anadirCedis: '',
            limiteCupon: '',
            descuentoValor: '',
            tipo: 1,
            limiteUso: '',
            limiteCliente: '',

        }
    },
    computed:{
        ...mapGetters({
            datosBasicos: 'cupones/cupones/datosBasicos',
        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        }
    },
    created(){
        this.getDatosBasicos()
    },
    methods:{
        async getDatosBasicos(){
            try {
                this.$store.dispatch('cupones/cupones/getDatosBasicos',this.id_cupon)

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.active-button{
    background-color: var(--color-general);
    color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
}
.inactive-button{
    background-color: #F5F5F5;
    color: #707070;
    box-shadow: 0px 3px 6px #00000029;
}
</style>
